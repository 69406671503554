import { useOrgURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useZones } from 'hooks/useZones';
import { useEffect } from 'react';
import { getDefaultFeedStartTime } from 'shared/utils/discussion';
import { InsightsFeed, InsightsFeedProps } from './InsightsFeed';

export const OrgInsightsPage = () => {
  const { zoneTimeZone, currentTimeInCurrentZone } = useCurrentZone();
  const { zones } = useZones();
  const { getRangeStartTime, setRangeStartTime } = useOrgURL();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const handleChangeStartTime: InsightsFeedProps['onChangeStartTime'] = (
    startTime
  ) => {
    setRangeStartTime({
      zonedDate: startTime.valueOf(),
      timeZone: zoneTimeZone,
    });
  };

  useEffect(() => {
    if (!rangeStartTime) {
      setRangeStartTime({
        zonedDate: getDefaultFeedStartTime(currentTimeInCurrentZone).valueOf(),
        timeZone: zoneTimeZone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeInCurrentZone, rangeStartTime, zoneTimeZone]);

  if (!rangeStartTime) {
    return null;
  }

  return (
    <InsightsFeed
      zones={zones}
      startTime={new Date(rangeStartTime)}
      onChangeStartTime={handleChangeStartTime}
    />
  );
};
