import { clamp } from './miscellaneous';

interface Color {
  type: string;
  values: number[];
}

/**
 * @param {string} hexColor - The hex color string
 * @returns {[number, number, number]} - The hex values of color
 */
export const getHexColorRGB = (hexColor: string): [number, number, number] => {
  return [
    Number.parseInt(hexColor.slice(1, 3), 16),
    Number.parseInt(hexColor.slice(3, 5), 16),
    Number.parseInt(hexColor.slice(5, 7), 16),
  ];
};

/**
 * Converts a color from CSS hex format to CSS rgb format.
 *
 * @param {string} color - Hex color, i.e. #nnn or #nnnnnn
 * @returns {string} A CSS rgb color string
 */
function hexToRgb(color: string): string {
  const values = getHexColorRGB(color);
  return `rgb(${values.join(',')})`;
}

/**
 * Returns an object with the type and values of a color.
 *
 * Note: Does not support rgb % values.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba()
 * @returns {object} - A color object: {type: string, values: number[]}
 */
function decomposeColor(color: string): Color {
  if (color.charAt(0) === '#') {
    return decomposeColor(hexToRgb(color));
  }

  const marker = color.indexOf('(');
  const type = color.substring(0, marker);

  if (['rgb', 'rgba'].indexOf(type) === -1) {
    throw new Error(
      `We support the following formats: #nnn, #nnnnnn, rgb(), rgba(). Got ${color}`
    );
  }

  const values = color.substring(marker + 1, color.length - 1).split(',');
  const numberValues = values.map(function (value) {
    return parseFloat(value);
  });
  return {
    type: type,
    values: numberValues,
  };
}

/**
 * Converts a color object with type and values to a string.
 *
 * @param {object} color - Decomposed color
 * @returns {string} A CSS color string
 */
function recomposeColor(color: Color): string {
  const type = color.type;
  const numberValues = color.values;

  const values = numberValues.map((v) => v.toString());

  return ''.concat(type, '(').concat(values.join(', '), ')');
}

/**
 * Set the absolute transparency of a color.
 * Any existing alpha value is overwritten.
 *
 * @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba()
 * @param {number} value - value to set the alpha channel to in the range 0-1
 * @returns {string} A CSS color string. Hex input values are returned as rgb
 */
export function alpha(color: string, value: number): string {
  const decomposedColor = decomposeColor(color);
  value = clamp(value, 0, 1);

  if (decomposedColor.type === 'rgb') {
    decomposedColor.type += 'a';
  }

  decomposedColor.values[3] = value;
  return recomposeColor(decomposedColor);
}
