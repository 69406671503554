import { useZones } from 'hooks/useZones';

export const OrgHomepage = () => {
  const { zones } = useZones();

  return (
    <div className="">
      {zones.map(({ uid, label }) => (
        <p key={uid}>{label}</p>
      ))}
    </div>
  );
};
