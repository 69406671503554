import { matchPath, matchRoutes } from 'react-router-dom';
import { AugmentedRouteObject } from 'Routes';
import { ERoutePath, PATH_PATTERNS } from 'shared/constants/url';
import { StoreArgs } from './URLStoreArgs';

export const getDefaultStore = (args: StoreArgs<ERoutePath.ROOT>) => {
  const { navigate } = args;

  return {
    isLoginPage: !!matchPath(ERoutePath.LOGIN, location.pathname),

    navigateToHome: () => {
      navigate(PATH_PATTERNS[ERoutePath.ROOT]);
    },
    navigateToSettings: () => {
      navigate(PATH_PATTERNS[ERoutePath.SETTINGS_EDIT_PROFILE]);
    },
    navigateToLogin: () => {
      navigate(PATH_PATTERNS[ERoutePath.LOGIN]);
    },
    navigateToResetPassword: (username: string) => {
      navigate(
        `${PATH_PATTERNS[ERoutePath.RESET_PASSWORD]}?${new URLSearchParams({
          username,
        }).toString()}`
      );
    },
    skipAuthCheck: (flattenedRoutes: AugmentedRouteObject[]) => {
      const [routeMatch] = (
        matchRoutes(flattenedRoutes, location) ?? []
      ).splice(-1);
      return !(
        routeMatch && (routeMatch.route as AugmentedRouteObject).shouldCheckAuth
      );
    },
  };
};
