import { useGetHealthDataByZoneId } from 'api/health-score';
import { Button } from 'components/common/Button/Button';
import { Chart, ChartProps } from 'components/common/Chart/Chart';
import { HorizontallyScrollable } from 'components/common/HorizontallyScrollable';
import { TabGroup } from 'components/common/TabGroup';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import {
  differenceInDays,
  endOfDay,
  format,
  hoursToMilliseconds,
  startOfDay,
  subDays,
} from 'date-fns';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { ArrowDownRightIcon, ArrowUpRightIcon, MinusIcon } from 'lucide-react';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  EMeasurementGroup,
  MeasurementTypeConfig,
  SignalMeasurementsType,
} from 'shared/interfaces/measurement';
import { cn } from 'shared/utils/cn';
import { isBetween } from 'shared/utils/getters';
import { smartRound } from 'shared/utils/miscellaneous';

const TIME_RANGES = [
  { id: '1', label: '1 day' },
  { id: '3', label: '3 days' },
  { id: '7', label: '7 days' },
];

const IDEAL_RANGE = {
  min: -34,
  max: 34,
};

export const HealthScore = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'>
>(function GrowthCycleNotes({ className, ...props }, ref) {
  const [timeRange, setTimeRange] = useState(TIME_RANGES[1]!);
  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const { navigateToLineChart, zoneId } = useZoneDetailsPageURL();
  const zonedStartTime = startOfDay(
    subDays(currentTimeInCurrentZone, Number(timeRange.id))
  );
  const zonedEndTime = endOfDay(subDays(currentTimeInCurrentZone, 1));
  const { staticSignals } = useTypeConfig();
  const signals = useMemo(
    () =>
      staticSignals.filter(
        ({ group }) => group === EMeasurementGroup.Environment
      ),
    [staticSignals]
  );
  const prevZonedStartTime = startOfDay(
    subDays(zonedStartTime, differenceInDays(zonedEndTime, zonedStartTime))
  );
  const prevZonedEndTime = endOfDay(subDays(zonedStartTime, 1));
  const previousScores = useGetHealthDataByZoneId({
    zoneId: Number(zoneId),
    zoneTimeZone,
    start: prevZonedStartTime,
    end: prevZonedEndTime,
    signals,
  });
  const currentScores = useGetHealthDataByZoneId({
    zoneId: Number(zoneId),
    zoneTimeZone,
    start: zonedStartTime,
    end: zonedEndTime,
    signals,
  });
  const { currentScore, Icon, trendIsBetter, trendIsWorse } = useMemo(() => {
    const computeScore = (data: SignalMeasurementsType) => {
      const allValues = [];
      for (const [_, scores] of data) {
        const values = scores.map(([_, value]) => value);
        allValues.push(...values);
      }
      if (allValues.length === 0) {
        return 0;
      }
      const totalCount = allValues.length;
      return (
        100 -
        allValues.reduce((acc, num) => acc + Math.abs(num), 0) / totalCount
      );
    };
    const previousScore = computeScore(previousScores.data);
    const currentScore = computeScore(currentScores.data);

    if (previousScore === 0 || currentScore === 0) {
      return {
        currentScore: 0,
        Icon: MinusIcon,
        trendIsBetter: false,
        trendIsWorse: false,
      };
    }

    const trendIsWorse = previousScore > currentScore;
    const trendIsBetter = previousScore < currentScore;
    const Icon = trendIsWorse
      ? ArrowDownRightIcon
      : trendIsBetter
        ? ArrowUpRightIcon
        : MinusIcon;

    return { currentScore, Icon, trendIsBetter, trendIsWorse };
  }, [previousScores.data, currentScores.data]);
  const chartAriaLabel = `Environment health score chart for ${currentZone?.label}`;
  const series = useMemo<Highcharts.SeriesSplineOptions[]>(
    function computeSeries() {
      const splineSeries: Highcharts.SeriesSplineOptions[] = [];

      for (const [signal, rawData] of currentScores.data) {
        const values = rawData.map(([_, value]) => Math.abs(value));
        const maxValue = Math.max(...values);
        const isOutOfRange = (y: number) =>
          maxValue === Math.abs(y) &&
          !isBetween(y, IDEAL_RANGE.min, IDEAL_RANGE.max);
        const topValues: number[] = [];
        const data = rawData.map<Highcharts.PointOptionsObject>(([x, y]) => {
          const showLabel = isOutOfRange(y) && !topValues.some((v) => v === y);

          if (showLabel) {
            topValues.push(y);
          }

          return {
            x,
            y,
            marker: {
              enabled: showLabel,
              symbol: 'circle',
              radius: 3,
            },
            dataLabels: {
              // allowOverlap: true,
              enabled: false,
              format: showLabel
                ? `${signal.label} at -- ${signal.unit}`
                : undefined,
            },
          };
        });

        splineSeries.push({
          id: signal.type,
          type: 'spline',
          name: signal.label,
          className: signal.style?.svg,
          data,
          gapSize: hoursToMilliseconds(1),
          gapUnit: 'value',
          dataLabels: {
            allowOverlap: false,
            borderRadius: 4,
            className: 'healthscore-data-label',
            crop: false,
            enabled: false,
            padding: 4,
            shape: 'callout',
            y: -12,
          },
        });
      }

      return splineSeries;
    },
    [currentScores.data]
  );
  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      return {
        accessibility: {
          description: chartAriaLabel,
        },
        boost: {
          enabled: false,
        },
        chart: {
          className: 'healthscore-chart',
          marginTop: 10,
          spacingTop: 0,
        },
        defs: {
          gradientIdealRange: {
            // key
            tagName: 'linearGradient',
            id: 'gradient-ideal-range',
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
            children: [
              {
                tagName: 'stop',
                offset: 0,
              },
              {
                tagName: 'stop',
                offset: 0.5,
              },
              {
                tagName: 'stop',
                offset: 1,
              },
            ],
          },
        },
        series,
        xAxis: {
          max: zonedEndTime.valueOf(),
          min: zonedStartTime.valueOf(),
          labels: {
            formatter: function () {
              const isMidnight = new Date(Number(this.value)).getHours() === 0;
              const dateString = isMidnight
                ? format(this.value, 'EEE')
                : format(this.value, 'h a');
              const className = isMidnight ? 'fill-gray-900 font-medium' : '';

              return `<span class="${className}">${dateString}</span>`;
            },
          },
        },
        yAxis: {
          labels: {
            enabled: false,
          },
          maxPadding: 0.1,
          plotBands: [
            {
              from: -105,
              to: 105,
              className: 'healthscore-plot-band',
            },
          ],
          plotLines: [
            {
              className: 'healthscore-plot-line',
              value: IDEAL_RANGE.max,
              label: {
                text: 'Ideal range',
                useHTML: true,
                x: 0,
                y: 20,
              },
            },
            { className: 'healthscore-plot-line', value: IDEAL_RANGE.min },
          ],
        },
      };
    },
    [chartAriaLabel, series, zonedEndTime, zonedStartTime]
  );
  const handleClickSignal = useCallback(
    (signal: MeasurementTypeConfig) => {
      navigateToLineChart({
        signalIds: [signal.type],
        zonedStartTime: zonedStartTime.valueOf(),
        zonedEndTime: zonedEndTime.valueOf(),
        timeZone: zoneTimeZone,
      });
    },
    [navigateToLineChart, zoneTimeZone, zonedEndTime, zonedStartTime]
  );

  return (
    <div
      ref={ref}
      {...props}
      className={cn(
        'w-full min-w-0 py-3 px-4 xl:py-4 xl:px-6 rounded bg-white text-gray-900 flex flex-col gap-3 xl:gap-4',
        className
      )}
    >
      <h2 className="w-full flex gap-3 justify-between">
        <div className="flex flex-col">
          <p className="text-sm lg:text-base font-semibold">
            Environment health score
          </p>
          <p className="text-lg lg:text-xl xl:text-2xl font-semibold flex gap-1 xl:gap-2 items-center">
            {smartRound(currentScore)}%
            <Icon
              className={cn(
                'icon shrink-0 size-6 lg:size-7 xl:size-8',
                'stroke-ash-500',
                trendIsWorse && 'stroke-red-500',
                trendIsBetter && 'stroke-green-500'
              )}
            />
          </p>
        </div>
        <TabGroup
          variant="tertiary"
          value={timeRange.id}
          tabs={TIME_RANGES}
          className="hidden lg:flex"
          onChange={(tab) =>
            setTimeRange(TIME_RANGES.find(({ id }) => id === tab)!)
          }
        />
      </h2>

      <HorizontallyScrollable>
        <div className={cn('w-fit flex gap-2 overflow-x-auto no-scrollbar')}>
          {signals
            .toSorted((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity))
            .map((signal) => {
              return (
                <Button
                  key={signal.type}
                  variant="tertiary"
                  onClick={() => handleClickSignal(signal)}
                >
                  <div className="flex gap-2 items-center justify-center">
                    <div
                      className={cn('size-4 rounded-full', signal.style?.bg)}
                    />
                    {signal.label}
                  </div>
                </Button>
              );
            })}
        </div>
      </HorizontallyScrollable>

      <div className="relative min-w-0 flex-auto">
        <Chart
          className="w-full h-full"
          aria-label={chartAriaLabel}
          options={options}
          loading={currentScores.loading || previousScores.loading}
        />
      </div>
    </div>
  );
});
