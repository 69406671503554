import { CenteredLoader } from 'components/common/CenteredLoader';
import { Header, HeaderProps, THeaderLink } from 'components/header/Header';
import { NotificationSideBar } from 'components/notifications/NotificationSideBar';
import { useAuth } from 'contexts/AuthProvider';
import {
  useOrgURL,
  useURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useLogOut } from 'hooks/useLogOut';
import { usePermissions } from 'hooks/usePermissions';
import { useZones } from 'hooks/useZones';
import isNil from 'lodash.isnil';
import { DoorOpenIcon, UserIcon } from 'lucide-react';
import { OrganizationNotFoundBoundary } from 'pages/not_found/OrganizationNotFoundBoundary';
import { Suspense, useCallback, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { PSEUDO_ZONE, ZONE_TAB_DETAILS } from 'shared/constants/zone';
import {
  EHeaderMenuLinks,
  EZoneDetailTabs,
  HLink,
} from 'shared/interfaces/zone';

const { HOMEPAGE, INSIGHTS } = EZoneDetailTabs;

const { LOGOUT, SETTINGS } = EHeaderMenuLinks;

export const OrgPage = () => {
  const {
    organizations,
    onChangeOrganization,
    currentlySelectedOrganization,
    isNeatleafOrganizationMember,
  } = useAuth();
  const { logOut } = useLogOut();
  const { canAccessUserSettings } = usePermissions();
  const { currentZone } = useCurrentZone();
  const { organizationCode, tab, setOrganization, navigateToTab } = useOrgURL();
  const zoneUrl = useZoneDetailsPageURL();
  const { navigateToHome, navigateToSettings } = useURL();
  const { zones } = useZones();

  const headerZones = useMemo(() => {
    return [PSEUDO_ZONE].concat(zones);
  }, [zones]);

  const handleClickTab: THeaderLink<HLink>['onClick'] = useCallback(
    ({ id }: THeaderLink<HLink>) => {
      const tabId = id as EZoneDetailTabs;
      navigateToTab({ tab: tabId });
    },
    [navigateToTab]
  );

  const headerLinks: THeaderLink<HLink>[] = useMemo(
    () => [
      {
        id: HOMEPAGE,
        label: ZONE_TAB_DETAILS[HOMEPAGE].label,
        location: 'zone-detail-tabs',
        enabled: isNeatleafOrganizationMember,
        onClick: handleClickTab,
      },
      {
        id: INSIGHTS,
        label: ZONE_TAB_DETAILS[INSIGHTS].label,
        onClick: handleClickTab,
        location: 'zone-detail-tabs',
      },
      {
        id: SETTINGS,
        label: 'User settings',
        location: 'settings-menu',
        enabled: canAccessUserSettings,
        onClick: navigateToSettings,
        leadingIcon: <UserIcon className="icon" />,
      },
      {
        id: LOGOUT,
        label: 'Logout',
        location: 'settings-menu',
        onClick: logOut,
        leadingIcon: <DoorOpenIcon className="icon" />,
      },
    ],
    [
      canAccessUserSettings,
      handleClickTab,
      isNeatleafOrganizationMember,
      logOut,
      navigateToSettings,
    ]
  );

  const selectedLink =
    headerLinks.find(({ id }) => id === tab) ?? headerLinks.at(0)!;

  const extraContent = useMemo(() => {
    return currentZone && zones.length > 0 ? (
      <NotificationSideBar zones={zones} currentZone={currentZone} />
    ) : null;
  }, [currentZone, zones]);

  const handleChangeOrg: HeaderProps<HLink>['onChangeOrg'] = (org) => {
    setOrganization(org.code);
  };

  const handleChangeZone: HeaderProps<HLink>['onChangeZone'] = (zone) => {
    if (isNil(currentlySelectedOrganization))
      throw new Error('No organization selected');
    if (zone.code !== PSEUDO_ZONE.code) {
      zoneUrl.navigateTo({
        organizationCode: currentlySelectedOrganization.code,
        zoneId: zone.id,
        tab,
      });
    }
  };

  useEffect(() => {
    if (
      organizations.length &&
      !organizations.find((org) => org.code === organizationCode)
    ) {
      if (!isNil(currentlySelectedOrganization)) {
        setOrganization(currentlySelectedOrganization.code, {
          replace: true,
        });
      }
    } else {
      const selectedOrganization = organizations.find(
        (org) => org.code === organizationCode
      );
      if (selectedOrganization) {
        onChangeOrganization(selectedOrganization);
      }
    }
  }, [
    organizationCode,
    onChangeOrganization,
    organizations,
    currentlySelectedOrganization,
    setOrganization,
  ]);

  useEffect(() => {
    if (!tab) {
      navigateToTab({
        organizationCode,
        tab: HOMEPAGE,
      });
    }
  }, [navigateToTab, organizationCode, tab]);

  return (
    <OrganizationNotFoundBoundary organization={currentlySelectedOrganization}>
      <Header
        orgs={organizations}
        zones={headerZones}
        links={headerLinks}
        selectedOrg={currentlySelectedOrganization}
        selectedZone={PSEUDO_ZONE}
        selectedLink={selectedLink}
        onChangeOrg={handleChangeOrg}
        onChangeZone={handleChangeZone}
        onClickHome={navigateToHome}
        extraContent={extraContent}
      />

      <main
        id="main"
        className="relative h-full px-4 lg:px-8 box-border overflow-x-hidden overflow-y-auto"
      >
        <Suspense fallback={<CenteredLoader />}>
          <Outlet />
        </Suspense>
      </main>

      <footer id="footer" className="pb-4 px-4 lg:px-8" />
    </OrganizationNotFoundBoundary>
  );
};
