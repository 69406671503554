import { EZoneDetailTabs, TZone } from 'shared/interfaces/zone';

export const DEFAULT_ZONE_MAP_URL = '/default_zone_map.svg';
const CRESCO_ROOM_101_UID = 'CRESCO_CRESCO_LINCOLN_ROOM_101';
const CRESCO_ROOM_101_MAP_URL = '/cresco_room_101_zone_map.svg';
const DEWEY_SCIENTIFIC_UID = 'DEWEY_SCIENTIFIC_PULLMAN_ARGENTINA';
const DEWEY_SCIENTIFIC_MAP_URL = '/dewey_scientific_zone_map.svg';
const NATURA_BAY_2_UID = 'NATURA_NATURA_GREENHOUSE_BAY_2';
const NATURA_BAY_2_MAP_URL = '/natura_bay-9.svg';

export const ZONE_TO_URL_MAP = new Map<string, string>([
  [CRESCO_ROOM_101_UID, CRESCO_ROOM_101_MAP_URL],
  [DEWEY_SCIENTIFIC_UID, DEWEY_SCIENTIFIC_MAP_URL],
  [NATURA_BAY_2_UID, NATURA_BAY_2_MAP_URL],
  [DEFAULT_ZONE_MAP_URL, DEFAULT_ZONE_MAP_URL],
]);

export const ZONE_TAB_DETAILS: Record<EZoneDetailTabs, { label: string }> = {
  [EZoneDetailTabs.HOMEPAGEV2]: {
    label: 'Home v2',
  },
  [EZoneDetailTabs.HOMEPAGE]: {
    label: 'Homepage',
  },
  [EZoneDetailTabs.LINE_CHART]: {
    label: 'Line chart',
  },
  [EZoneDetailTabs.HEAT_MAP]: {
    label: 'Heat map',
  },
  [EZoneDetailTabs.IMAGE_FEED]: {
    label: 'Image feed',
  },
  [EZoneDetailTabs.GROWTH_CYCLE_SETTINGS]: {
    label: 'Growth cycle settings',
  },
  [EZoneDetailTabs.INSIGHTS]: {
    label: 'Insights',
  },
  [EZoneDetailTabs.GROWTH_CYCLE_REPORT]: {
    label: 'Reports',
  },
};

export const PSEUDO_ZONE: TZone = {
  id: -1,
  uid: 'all',
  code: 'all',
  label: 'All facilities',
  metadata: {},
  timeZone: '',
  locationId: -1,
  locationName: '',
  organizationCode: '',
};
