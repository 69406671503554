import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Chip } from 'components/common/Chip/Chip';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useSignals } from 'hooks/useSignals';
import { DiamondIcon } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import {
  EMeasurementGroup,
  EMeasurementTypes,
  MeasurementAggregation,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { GrowthCycleReportLineChart } from './GrowthCycleReportLineChart';

const {
  CalculatedDistance,
  ObjectBud,
  YellowingGeneral,
  NecrosisGeneral,
  AbnormalShapeFolding,
  OtherPowder,
} = EMeasurementTypes;

interface HealthIndicatorsProps {
  zone: TZone;
  startTime: number;
  endTime: number;
}
export const HealthIndicators = ({
  zone,
  startTime,
  endTime,
}: HealthIndicatorsProps) => {
  const { getMeasurementType } = useTypeConfig();

  const plantHealthSignals = useMemo(
    () => [
      getMeasurementType(CalculatedDistance),
      getMeasurementType(ObjectBud),
      getMeasurementType(YellowingGeneral),
      getMeasurementType(NecrosisGeneral),
      getMeasurementType(AbnormalShapeFolding),
      getMeasurementType(OtherPowder),
    ],
    [getMeasurementType]
  );

  const { signalIds, updateSignalIds, signals } = useSignals({
    zoneUid: zone.uid,
    startTime: startTime,
    endTime: endTime,
    measurementAggregation: MeasurementAggregation.AVERAGE,
  });

  const selectedSignals = useMemo(() => {
    return signals.filter((s) => s.group === EMeasurementGroup.PlantHealth);
  }, [signals]);

  const { data } = useGetMeasurementsByTypeAndTimeRange({
    zoneId: zone.id,
    zoneUid: zone.uid,
    zoneTimeZone: zone.timeZone,
    start: new Date(startTime),
    end: new Date(endTime),
    signals: selectedSignals,
    aggregation: MeasurementAggregation.AVERAGE,
  });

  const handleToggleSignals = useCallback(
    (signals: MeasurementTypeConfig[]) => () => {
      const ids = signals.map(({ type }) => type as string);
      const checkedIds = signalIds.filter((id) => ids.includes(id));

      if (checkedIds.length < ids.length) {
        // check ids
        updateSignalIds(Array.from(new Set([...signalIds, ...ids])));
      } else {
        // uncheck ids
        updateSignalIds(signalIds.filter((id) => !ids.includes(id)));
      }
    },
    [signalIds, updateSignalIds]
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-2 p-6 bg-white rounded-t">
        <h2 className="font-semibold">Health indicators over time</h2>
        <p>
          Select any parameter that you would like to display for this growth
          cycle report. All selected parameters will be displayed as charts
          below.
        </p>
        <div className="flex flex-wrap gap-2">
          {plantHealthSignals.map((signal) => {
            return (
              <Chip
                variant="neutral"
                key={signal.type}
                selected={signalIds.includes(signal.type)}
                onClick={handleToggleSignals([signal])}
                className="cursor-pointer"
              >
                <DiamondIcon
                  className={cn(
                    'icon fill-current',
                    signal.style?.stroke,
                    signal.style?.text
                  )}
                />
                {signal.label}
              </Chip>
            );
          })}
        </div>
      </div>
      {selectedSignals.map((signal) => {
        const dataLabelFor =
          signal.type === EMeasurementTypes.CalculatedDistance
            ? 'firstlast'
            : 'max';
        return (
          <div
            key={`signal-${signal.type}`}
            className="flex flex-col gap-2 p-6 bg-white last:rounded-b items-center"
          >
            <Chip variant="neutral" selected={true}>
              <DiamondIcon
                className={cn(
                  'icon fill-current',
                  signal.style?.stroke,
                  signal.style?.text
                )}
              />
              {signal.label}
            </Chip>
            <GrowthCycleReportLineChart
              height={230}
              signal={signal}
              measurementData={data.get(signal) ?? []}
              start={startTime}
              end={endTime}
              showDataLabel={true}
              showYAxis={false}
              dataLabelFor={dataLabelFor}
            />
          </div>
        );
      })}
    </div>
  );
};
