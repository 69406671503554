import { useQuery } from '@tanstack/react-query';
import { useApi } from 'contexts/ApiProvider';
import { getRandomStyle } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useMemo } from 'react';
import {
  MeasurementAggregation,
  MeasurementTypeConfig,
  MeasurementUnit,
  Signal,
  TPreferredUnits,
} from 'shared/interfaces/measurement';
import { convertCelsiusToFahrenheit, noop } from 'shared/utils/converters';

interface SignalsResponse {
  signals: Signal[];
}

export const useSignalsQuery = ({
  zoneUid,
  start,
  end,
  aggregation,
  preferredUnits,
}: {
  zoneUid: Maybe<string>;
  start?: Maybe<number>;
  end?: Maybe<number>;
  aggregation?: Maybe<MeasurementAggregation>;
  preferredUnits?: Maybe<TPreferredUnits>;
}) => {
  const { apiUrl, httpClient } = useApi();
  const params = new URLSearchParams({
    zoneUid: String(zoneUid),
    ...(start && { start: String(start / 1000) }),
    ...(end && { end: String(end / 1000) }),
    ...(aggregation && { aggregation: aggregation.toLowerCase() }),
  });
  const url = new URL(
    `dashboard/v1/signals?${params.toString()}`,
    apiUrl
  ).toString();

  const { data: rawData, ...result } = useQuery<SignalsResponse, Error>({
    queryKey: ['signals', params.toString()],
    queryFn: () => httpClient.get(url).json<SignalsResponse>(),
    enabled: !!zoneUid,
  });

  const data = useMemo(() => {
    if (!rawData) {
      return [];
    }

    return rawData.signals.map((signal) => {
      const { unit, convertFromUnit } = (() => {
        const { measurementUnit } = signal.measurementInformation;

        if (measurementUnit.includes('degree')) {
          const userWantsCelsius =
            preferredUnits &&
            Object.values(preferredUnits).some(
              (preferredUnit) => preferredUnit === 'celsius'
            );
          const unit = userWantsCelsius
            ? MeasurementUnit.degree_celsius
            : measurementUnit !== 'degree_celsius'
              ? MeasurementUnit[measurementUnit]
              : MeasurementUnit.FahrenheitDegree;
          const convertFromUnit =
            measurementUnit === 'degree_celsius' && userWantsCelsius
              ? noop
              : convertCelsiusToFahrenheit;

          return {
            unit,
            convertFromUnit,
          };
        }

        return {
          unit: MeasurementUnit[measurementUnit],
          convertFromUnit: noop,
        };
      })();
      const sensorName =
        signal.sensorInformation.sensorName ||
        signal.sensorInformation.sensorTypeName;
      const label =
        signal.defaultGroup !== signal.name
          ? `${signal.name} (${sensorName})`
          : sensorName;

      return {
        apis: ['rest-measurements'],
        source: signal.measurementInformation.source,
        aggregation: signal.measurementInformation.aggregation,
        group: signal.defaultGroup,
        type: signal.id,
        statisticsKeyV2: signal.id,
        unit,
        label,
        hasMultipleAggregations: true,
        style: getRandomStyle(
          signal.defaultGroup === signal.name ? signal.defaultGroup : label
        ),
        convertFromUnit,
      } as MeasurementTypeConfig;
    });
  }, [preferredUnits, rawData]);

  return { data, ...result };
};
