import { useGetHealthDataByZoneId } from 'api/health-score';
import { useTypeConfig } from 'contexts/TypeConfigProvider/TypeConfigProvider';
import { useMemo } from 'react';
import { EMeasurementGroup } from 'shared/interfaces/measurement';
import { toFixed } from 'shared/utils/miscellaneous';

interface EnvironmentHealthSummaryProps {
  zoneId: number;
  zoneTimeZone: string;
  start: Date;
  end: Date;
}

export const EnvironmentHealthSummary = ({
  zoneId,
  zoneTimeZone,
  start,
  end,
}: EnvironmentHealthSummaryProps) => {
  const { staticSignals } = useTypeConfig();
  const signals = useMemo(
    () =>
      staticSignals.filter(
        ({ group }) => group === EMeasurementGroup.Environment
      ),
    [staticSignals]
  );

  const { data } = useGetHealthDataByZoneId({
    signals,
    zoneTimeZone,
    zoneId,
    start,
    end,
  });

  const scores = useMemo(() => {
    return [...data.entries()].map(([signal, data]) => {
      return {
        signal: signal.label,
        order: signal.order,
        // Score for each signal is calculated by taking the average of all absolute values and substracting it from 100
        score:
          100 -
          data
            .map((item) => Math.abs(item[1]))
            .reduce((prev, curr) => prev + curr, 0) /
            data.length,
      };
    });
  }, [data]);

  const score =
    scores.reduce((prev, curr) => prev + curr.score, 0) / scores.length;

  if (!data.size) {
    return null;
  }

  return (
    <div className="flex gap-6">
      <div className="bg-white basis-1/2 rounded py-4 px-6 flex flex-col gap-2">
        <h3 className="font-semibold text-lg">Environment scores</h3>
        <div className="flex flex-col gap-3">
          <p className="font-semibold text-2xl">
            {isNaN(score) ? 'N/A' : `${toFixed(score)}%`}
          </p>
          <div className="flex flex-col gap-2">
            {scores
              .sort((a, b) => (a.order ?? Infinity) - (b.order ?? Infinity))
              .map((score) => {
                return (
                  <div
                    key={score.signal}
                    className="flex gap-3 justify-between"
                  >
                    <span>{score.signal}</span>
                    <span>
                      {isNaN(score.score) ? 'N/A' : `${toFixed(score.score)}%`}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="bg-white basis-1/2 rounded py-4 px-6 flex flex-col gap-2">
        <h3 className="font-semibold text-lg">
          How are our scores calculated?
        </h3>
        <div className="flex flex-col gap-3">
          <p>
            We calculate your health score based on the time and extent that
            parameters are out of range. Staying entirely within range results
            in a score of 100, while staying far out of range scores zero.
          </p>
          <p>
            Compare this number week-to-week or cycle-to-cycle to understand
            whether you are meeting your environment goals.
          </p>
        </div>
      </div>
    </div>
  );
};
