import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { NavigateOptions } from 'react-router-dom';
import { ERoutePath } from 'shared/constants/url';
import { EZoneDetailTabs } from 'shared/interfaces/zone';
import { navigateToFactory } from './NavigateToFactory';
import { QueryParamsSchema, getQueryParams } from './URLQueryParams';
import { StoreArgs } from './URLStoreArgs';

export const getOrgURLStore = (args: StoreArgs<ERoutePath.ORG>) => {
  const {
    routePath,
    searchParams,
    setSearchParams,
    navigate,
    pathParams,
    location,
  } = args;
  const queryParams = getQueryParams(searchParams, routePath);
  const navigateTo = navigateToFactory(
    navigate,
    location.pathname,
    searchParams,
    routePath
  );
  type SchemaType = QueryParamsSchema<typeof routePath>;

  const actions = {
    setOrganization: (
      organizationCode: string,
      navigateOptions?: NavigateOptions
    ) => {
      navigateTo(
        {
          pathParams: { organizationCode },
          clearPreviousQueryParams: true,
        },
        navigateOptions
      );
    },
    setRangeStartTime: ({
      zonedDate,
      timeZone,
    }: {
      zonedDate: SchemaType['ts-start'];
      timeZone: string;
    }) => {
      const date = zonedDate && fromZonedTime(zonedDate, timeZone).valueOf();
      setSearchParams({ 'ts-start': date }, { replace: true });
    },
    setRangeTime: (
      {
        zonedStart,
        zonedEnd,
        timeZone,
      }: {
        zonedStart: SchemaType['ts-start'];
        zonedEnd: SchemaType['ts-end'];
        timeZone: string;
      },
      navigateOptions: NavigateOptions = {
        replace: true,
      }
    ) => {
      const start = zonedStart && fromZonedTime(zonedStart, timeZone).valueOf();
      const end = zonedEnd && fromZonedTime(zonedEnd, timeZone).valueOf();
      setSearchParams(
        {
          'ts-start': start,
          'ts-end': end,
        },
        navigateOptions
      );
    },
    navigateToTab: (
      {
        organizationCode,
        tab,
      }: { organizationCode?: string; tab: EZoneDetailTabs },
      navigateOptions?: NavigateOptions
    ) => {
      const clearPreviousQueryParams = [
        EZoneDetailTabs.HOMEPAGE,
        EZoneDetailTabs.INSIGHTS,
      ].includes(tab);
      navigateTo(
        {
          pathParams: {
            organizationCode: organizationCode ?? pathParams?.organizationCode,
            tab,
          },
          clearPreviousQueryParams,
        },
        navigateOptions
      );
    },
  };

  const state = {
    getRangeStartTime: (timeZone: string) =>
      queryParams['ts-start'] === undefined
        ? undefined
        : toZonedTime(queryParams['ts-start'], timeZone).valueOf(),
    getRangeEndTime: (timeZone: string) =>
      queryParams['ts-end'] === undefined
        ? undefined
        : toZonedTime(queryParams['ts-end'], timeZone).valueOf(),
    organizationCode: pathParams?.organizationCode,
    tab: pathParams?.tab as EZoneDetailTabs,
    rangeStartTime: queryParams['ts-start'],
    rangeEndTime: queryParams['ts-end'],
  };
  return {
    ...actions,
    ...state,
  };
};
