import { useCurrentZone } from 'hooks/useCurrentZone';
import { useGrowthCycleReport } from 'hooks/useGrowthCycleReport';
import { EnvironmentHealthSummary } from './EnvironmentHealthSummary';
import { EnvironmentalIndicators } from './EnvironmentalIndicators';

export const GrowthCycleReportEnvironmentHealth = () => {
  const { selectedGrowthCycle } = useGrowthCycleReport();
  const { currentZone, zoneTimeZone } = useCurrentZone();

  if (
    !selectedGrowthCycle ||
    !currentZone ||
    !selectedGrowthCycle.start_time ||
    !selectedGrowthCycle.end_time
  ) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-xl">Environment health</h2>
      <div className="flex flex-col gap-6">
        <EnvironmentHealthSummary
          zoneId={currentZone.id}
          zoneTimeZone={zoneTimeZone}
          start={new Date(selectedGrowthCycle.start_time)}
          end={new Date(selectedGrowthCycle.end_time)}
        />
        <EnvironmentalIndicators
          zone={currentZone}
          startTime={selectedGrowthCycle.start_time}
          endTime={selectedGrowthCycle.end_time}
        />
      </div>
    </div>
  );
};
