import { useGetZonesByOrganizationCode } from 'api/zone';
import {
  useOrgURL,
  useSettingsURL,
  useZoneDetailsPageURL,
} from 'contexts/URLStoreProvider/URLStoreProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { BetaFlag } from 'shared/models/beta-flag';
import { useUserPermissions } from './useUserPermissions';

export const useZones = () => {
  const { organizationCode: zoneDetailsOrg } = useZoneDetailsPageURL();
  const { organization: settingsOrg } = useSettingsURL();
  const { organizationCode: rootOrg } = useOrgURL();
  const { getBetaFlagValue } = useFeatureFlags();
  const { hasAccessToZone } = useUserPermissions();
  const showInactiveZones = getBetaFlagValue(BetaFlag.VIEW_INACTIVE_ZONES);
  const orgCode = zoneDetailsOrg ?? settingsOrg ?? rootOrg;

  const { zones, ...result } = useGetZonesByOrganizationCode({
    organizationCode: orgCode,
    hideInactiveZones: !showInactiveZones,
    isVisible: hasAccessToZone,
  });

  return { ...result, zones };
};
