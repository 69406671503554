import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getNodeByKey,
  $getRoot,
  $nodesOfType,
  createEditor,
  EditorConfig,
  EditorState,
  ElementNode,
  LexicalEditor,
} from 'lexical';
import {
  BeautifulMentionComponentProps,
  BeautifulMentionNode,
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsPlugin,
  SerializedBeautifulMentionNode,
  ZeroWidthNode,
  ZeroWidthPlugin,
} from 'lexical-beautiful-mentions';
import isNil from 'lodash.isnil';
import { XIcon } from 'lucide-react';
import { ElementType, forwardRef } from 'react';
import { TMention } from 'shared/interfaces/discussion';
import { cn } from 'shared/utils/cn';
import { getValidEditorState } from 'shared/utils/textEditor';

const CustomMentionsMenu = ({
  loading: _loading,
  ...props
}: BeautifulMentionsMenuProps) => {
  return (
    <div className="absolute z-10 bg-white rounded-sm shadow w-fit h-fit overflow-hidden">
      <div
        className="flex flex-col gap-2 max-h-[268px] h-fit overflow-auto"
        {...props}
      />
    </div>
  );
};

const CustomMentionsMenuItem = forwardRef<
  HTMLDivElement,
  BeautifulMentionsMenuItemProps
>(function CustomMentionsMenuItem({ selected, ...props }, ref) {
  return (
    <div
      className={cn(
        `m-0 flex flex-col cursor-pointer p-2 w-full`,
        selected && 'bg-neutral-200'
      )}
      {...props}
      ref={ref}
    >
      <div>{props.item.value}</div>
      <div className="text-sm text-neutral-500">{props.item.data?.email}</div>
    </div>
  );
});

export const MentionsPlugin = ({
  availableMentions,
}: {
  availableMentions?: TMention[];
}) => {
  const mentionItems = availableMentions && {
    '@': availableMentions.map((mention) => ({
      id: (mention.user_id || mention.user_group_id)!,
      mentionType: !isNil(mention.user_id) ? 'user' : 'group',
      value: mention.name,
      email: mention.email,
    })),
  };

  if (!mentionItems) return null;

  return (
    <>
      <ZeroWidthPlugin />
      <BeautifulMentionsPlugin
        items={mentionItems}
        menuItemComponent={CustomMentionsMenuItem}
        menuComponent={CustomMentionsMenu}
        menuItemLimit={false}
      />
    </>
  );
};

const createCustomMentionsComponent = (nodeKey: string) =>
  forwardRef<HTMLDivElement, BeautifulMentionComponentProps>(
    function CustomMentionComponent({ trigger, value, ...other }, ref) {
      const [editor] = useLexicalComposerContext();

      const handleDelete = () => {
        editor.update(() => {
          const node = $getNodeByKey(nodeKey);
          if (node) {
            node.remove();
          }
        });
      };

      return (
        <span {...other} ref={ref} title={trigger + value}>
          {trigger}
          {value}{' '}
          {editor.isEditable() && (
            <>
              <XIcon
                onClick={handleDelete}
                className="stroke-[1.5px] size-4 text-[#999999] inline-block cursor-pointer"
              />{' '}
            </>
          )}
        </span>
      );
    }
  );

export class CustomMentionsNode extends BeautifulMentionNode {
  static getType() {
    return 'custom-beautifulMention';
  }
  static clone(node: CustomMentionsNode) {
    return new CustomMentionsNode(
      node.__trigger,
      node.__value,
      node.__data,
      node.__key
    );
  }
  static importJSON(serializedNode: SerializedBeautifulMentionNode) {
    return new CustomMentionsNode(
      serializedNode.trigger,
      serializedNode.value,
      serializedNode.data
    );
  }
  exportJSON(): SerializedBeautifulMentionNode {
    return Object.assign(super.exportJSON(), {
      type: 'custom-beautifulMention',
    });
  }
  component(): ElementType<BeautifulMentionComponentProps> | null {
    return createCustomMentionsComponent(this.__key);
  }
  decorate(editor: LexicalEditor, config: EditorConfig): React.JSX.Element {
    return super.decorate(editor, config);
  }
}

export const MentionsNodes = [
  CustomMentionsNode,
  {
    replace: BeautifulMentionNode,
    with: (node: BeautifulMentionNode) => {
      return new CustomMentionsNode(
        node.getTrigger(),
        node.getValue(),
        node.getData()
      );
    },
  },
  ZeroWidthNode,
];

export const getEditorTextAsStringifiedHtml = (
  editorStateString: string
): string => {
  const editor = createEditor({
    nodes: [...MentionsNodes],
  });
  const {
    editorState: validEditorState,
    isObject,
    isString,
  } = getValidEditorState(editorStateString);
  if (!isObject && !isString) throw new Error('Invalid editor state');
  const editorState = editor.parseEditorState(
    isObject
      ? JSON.stringify((validEditorState as EditorState).toJSON())
      : (validEditorState as string)
  );
  let text = '';
  editorState.read(() => {
    text = ($getRoot().getChildren().at(0) as ElementNode)
      .getChildren()
      .map((node) => {
        const content = node.getTextContent();
        if (node.getType() === CustomMentionsNode.getType()) {
          return `<b>${content}</b>`;
        } else {
          return content;
        }
      })
      .join('');
  });
  return text;
};

export const getMentionsIds = (editorStateString: string): number[] => {
  const editor = createEditor({
    nodes: [...MentionsNodes],
  });
  const {
    editorState: validEditorState,
    isObject,
    isString,
  } = getValidEditorState(editorStateString);
  if (!isObject && !isString) throw new Error('Invalid editor state');
  const editorState = editor.parseEditorState(
    isObject
      ? JSON.stringify((validEditorState as EditorState).toJSON())
      : (validEditorState as string)
  );
  const mentions: number[] = [];
  editorState.read(() => {
    const mentionNodes = $nodesOfType(CustomMentionsNode);
    mentionNodes.forEach((node) => {
      const data = node.getData();
      data && mentions.push(Number(data.id));
    });
  });
  return mentions;
};
