import { useZoneDetailsPageURL } from 'contexts/URLStoreProvider/URLStoreProvider';
import { useCurrentZone } from 'hooks/useCurrentZone';
import { useEffect } from 'react';
import { getDefaultFeedStartTime } from 'shared/utils/discussion';
import { InsightsFeed, InsightsFeedProps } from './InsightsFeed';

export const ZoneInsightsPage = () => {
  const { currentZone, currentTimeInCurrentZone, zoneTimeZone } =
    useCurrentZone();
  const { getRangeStartTime, setRangeStartTime } = useZoneDetailsPageURL();
  const rangeStartTime = getRangeStartTime(zoneTimeZone);
  const handleChangeStartTime: InsightsFeedProps['onChangeStartTime'] = (
    startTime
  ) => {
    setRangeStartTime({
      zonedDate: startTime.valueOf(),
      timeZone: zoneTimeZone,
    });
  };

  useEffect(() => {
    if (!rangeStartTime) {
      setRangeStartTime({
        zonedDate: getDefaultFeedStartTime(currentTimeInCurrentZone).valueOf(),
        timeZone: zoneTimeZone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTimeInCurrentZone, rangeStartTime, zoneTimeZone]);

  if (!currentZone || !rangeStartTime) {
    return null;
  }

  return (
    <InsightsFeed
      zones={[currentZone]}
      startTime={new Date(rangeStartTime)}
      onChangeStartTime={handleChangeStartTime}
    />
  );
};
