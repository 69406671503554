import { useGetMeasurementsByTypeAndTimeRange } from 'api/measurement';
import { Chip } from 'components/common/Chip/Chip';
import { Dropdown, Option } from 'components/common/Dropdown/Dropdown';
import { useSignals } from 'hooks/useSignals';
import groupBy from 'lodash.groupby';
import { DiamondIcon } from 'lucide-react';
import { useCallback, useMemo } from 'react';
import {
  EMeasurementGroup,
  MeasurementAggregation,
  MeasurementTypeConfig,
} from 'shared/interfaces/measurement';
import { TZone } from 'shared/interfaces/zone';
import { cn } from 'shared/utils/cn';
import { GrowthCycleReportLineChart } from './GrowthCycleReportLineChart';

interface EnvironmentalIndicatorsProps {
  zone: TZone;
  startTime: number;
  endTime: number;
}
export const EnvironmentalIndicators = ({
  zone,
  startTime,
  endTime,
}: EnvironmentalIndicatorsProps) => {
  const { allSignals, signalIds, updateSignalIds, signals } = useSignals({
    zoneUid: zone.uid,
    startTime: startTime,
    endTime: endTime,
    measurementAggregation: MeasurementAggregation.AVERAGE,
  });

  const environmentalSignalGroups = useMemo(() => {
    return groupBy(
      allSignals.filter(
        (s) =>
          s.group !== EMeasurementGroup.PlantHealth &&
          s.group !== undefined &&
          (s.aggregation === undefined ||
            s.aggregation === MeasurementAggregation.AVERAGE.toLowerCase())
      ),
      (s) => s.group
    );
  }, [allSignals]);

  const groupNames = useMemo(() => {
    return Object.keys(environmentalSignalGroups);
  }, [environmentalSignalGroups]);

  const selectedSignals = useMemo(() => {
    return signals.filter((s) => s.group !== EMeasurementGroup.PlantHealth);
  }, [signals]);

  const { data } = useGetMeasurementsByTypeAndTimeRange({
    zoneId: zone.id,
    zoneUid: zone.uid,
    zoneTimeZone: zone.timeZone,
    start: new Date(startTime),
    end: new Date(endTime),
    signals: selectedSignals,
    aggregation: MeasurementAggregation.AVERAGE,
  });

  const dataKeys = useMemo(() => {
    return Array.from(data.keys());
  }, [data]);

  const handleToggleSignals = useCallback(
    (signals: MeasurementTypeConfig[]) =>
      (
        options:
          | Option<MeasurementTypeConfig>[]
          | Option<MeasurementTypeConfig>
          | null
      ) => {
        if (!Array.isArray(options)) {
          return;
        }
        const allIdsOfGroup = signals.map(({ type }) => type as string);
        const newlySelectedIds = options.map((o) => o.value.type as string);

        updateSignalIds(
          Array.from(
            new Set([
              ...signalIds.filter((id) => !allIdsOfGroup.includes(id)),
              ...newlySelectedIds,
            ])
          )
        );
      },
    [signalIds, updateSignalIds]
  );

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-col gap-2 p-6 bg-white rounded-t last:rounded-b">
        <h2 className="font-semibold">Environmental indicators over time</h2>
        <p>
          Select any parameter that you would like to display for this growth
          cycle report. All selected parameters will be displayed as charts
          below.
        </p>
        <div className="flex flex-wrap gap-2">
          {groupNames.map((groupName) => {
            const groupSignals = environmentalSignalGroups[groupName]!.sort(
              (a, b) => (a.order ?? Infinity) - (b.order ?? Infinity)
            );
            const options = groupSignals.map((s) => ({
              value: s,
              label: s.label,
            }));
            const selectedOptions = options.filter((o) =>
              selectedSignals.some((s) => s.type === o.value.type)
            );
            return (
              <Dropdown
                key={groupName}
                options={options}
                variant="secondary"
                multiple={true}
                value={selectedOptions}
                onChange={handleToggleSignals(groupSignals)}
                selectedOptionRenderer={(options) => {
                  if (!Array.isArray(options)) {
                    return groupName;
                  }
                  return options.length
                    ? `${groupName} (${options.length})`
                    : groupName;
                }}
              />
            );
          })}
        </div>
      </div>
      {selectedSignals.map((signal) => {
        const measurementKey = dataKeys.find((k) => k.type === signal.type);

        if (!measurementKey) {
          return null;
        }

        const measurementData = data.get(measurementKey) || [];

        return (
          <div
            key={`signal-${signal.type}`}
            className="flex flex-col gap-2 p-6 bg-white last:rounded-b items-center"
          >
            <Chip variant="neutral" selected={true}>
              <DiamondIcon
                className={cn(
                  'icon fill-current',
                  signal.style?.stroke,
                  signal.style?.text
                )}
              />
              {signal.label}
            </Chip>
            <GrowthCycleReportLineChart
              height={230}
              signal={signal}
              measurementData={measurementData}
              start={startTime}
              end={endTime}
              showDataLabel={false}
              showYAxis={true}
            />
          </div>
        );
      })}
    </div>
  );
};
