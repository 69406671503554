import { Chart, ChartProps } from 'components/common/Chart/Chart';
import { differenceInDays, hoursToMilliseconds } from 'date-fns';
import { useMemo } from 'react';
import { MeasurementTypeConfig } from 'shared/interfaces/measurement';
import { smartRound } from 'shared/utils/miscellaneous';

export interface GrowthCycleReportLineChartProps {
  height: number;
  signal: MeasurementTypeConfig;
  measurementData: [number, number][];
  start: number;
  end: number;
  showDataLabel: boolean;
  dataLabelFor?: 'max' | 'firstlast';
  showYAxis: boolean;
}

export const GrowthCycleReportLineChart = ({
  measurementData,
  height,
  signal,
  start,
  end,
  showDataLabel,
  showYAxis,
  dataLabelFor = 'max',
}: GrowthCycleReportLineChartProps) => {
  const chartAriaLabel = `Line chart for ${signal.label}`;
  const options = useMemo<ChartProps['options']>(
    function computeOptions() {
      const values = measurementData.map(([_, value]) => value);
      const maxValue = Math.max(...values);
      const topValues: number[] = [];
      const data = measurementData.map<Highcharts.PointOptionsObject>(
        ([x, y], index) => {
          const showMaxLabel =
            showDataLabel &&
            dataLabelFor === 'max' &&
            maxValue === y &&
            !topValues.some((v) => v === y);
          if (showMaxLabel) {
            topValues.push(y);
          }
          const showFirstLabel =
            showDataLabel && dataLabelFor === 'firstlast' && index === 0;
          const showLastLabel =
            showDataLabel &&
            dataLabelFor === 'firstlast' &&
            index === measurementData.length - 1;

          const showLabel = showMaxLabel || showFirstLabel || showLastLabel;

          return {
            x,
            y,
            marker: {
              enabled: showLabel,
              symbol: 'circle',
              radius: 3,
            },
            dataLabels: {
              enabled: showLabel,
              format: showLabel ? `${smartRound(y)} ${signal.unit}` : undefined,
              verticalAlign: dataLabelFor === 'firstlast' ? 'top' : 'bottom',
              align: dataLabelFor === 'firstlast' ? 'right' : 'center',
              x: showFirstLabel ? 12 : showLastLabel ? -12 : 0,
            },
          };
        }
      );

      const series: Highcharts.SeriesSplineOptions[] = [
        {
          id: signal.type,
          type: 'spline',
          name: signal.label,
          className: signal.style?.svg,
          yAxis: signal.unit,
          data,
          gapSize: hoursToMilliseconds(1),
          gapUnit: 'value',
          dataLabels: {
            allowOverlap: false,
            borderRadius: 4,
            className: 'growth-cycle-report-data-label',
            crop: false,
            enabled: true,
            padding: 4,
            shape: 'callout',
            y: -12,
          },
        },
      ];

      return {
        accessibility: {
          description: chartAriaLabel,
        },
        boost: {
          pixelRatio: 0,
        },
        chart: {
          className: 'growth-cycle-report-line-chart',
          marginTop: 10,
          spacingTop: 0,
        },
        xAxis: {
          max: end.valueOf(),
          min: start.valueOf(),
          minorTicks: true,
          minorTickInterval: hoursToMilliseconds(24),
          minorTickLength: 4,
          minorTickWidth: 1,
          tickPixelInterval: 60,
          labels: {
            formatter: function () {
              const currentDay =
                differenceInDays(Number(this.value), start) + 1;
              const currentWeek = Math.floor(currentDay / 7) + 1;

              return `Week ${currentWeek}`;
            },
          },
        },
        yAxis: {
          id: signal.unit,
          maxPadding: 0.2,
          reversed: signal.reversedYAxis,
          visible: showYAxis,
        },
        series,
      };
    },
    [
      chartAriaLabel,
      dataLabelFor,
      end,
      measurementData,
      showDataLabel,
      showYAxis,
      signal.label,
      signal.reversedYAxis,
      signal.style?.svg,
      signal.type,
      signal.unit,
      start,
    ]
  );

  return (
    <Chart style={{ height }} options={options} aria-label={chartAriaLabel} />
  );
};
